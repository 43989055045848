<template>
    <b-container v-if="extra">
        <b-row>
            <b-col>
                <h3 class="mt-3">{{ extra.id ? '修改選項分類' : '建立選項分類' }}</h3>
            </b-col>
            <b-col v-if="extra.id">
                <b-button class="mt-3 float-right" variant="danger" @click="onDelete">刪除選項分類</b-button>
            </b-col>
        </b-row>

        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="名稱">
                <b-form-input v-model="extra.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="短稱 (打印名稱)">
                <b-form-input v-model="extra.shortName"></b-form-input>
            </b-form-group>

            <h5>選項</h5>
            <b-form-group label="最少可選選項">
                <b-form-input v-model.number="extra.minSelected" type="number" step="1" required></b-form-input>
            </b-form-group>
            <b-form-group label="最多可選選項">
                <b-form-input v-model.number="extra.maxSelected" type="number" step="1" required></b-form-input>
            </b-form-group>
            <b-form-group label="可選選項">
                <n-multiselect label="name" :options="itemOptions" :reset-after="true" @select="addItem"></n-multiselect>
            </b-form-group>
            <b-form-group v-if="extra.items.length > 0">
                <n-draggable class="list-group" :list="extra.items">
                    <b-list-group-item v-for="(item, index) in extra.items" :key="index">
                        <b-row>
                            <b-col>{{ item.name }}</b-col>
                        </b-row>
                        <b-row>
                            <b-col class="mt-3" md="6" lg="4">
                                <b-input-group prepend="自取$">
                                    <b-form-input v-model.number="item.price.PICKUP" type="number" step="0.1" required></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col class="mt-3" md="6" lg="4">
                                <b-input-group prepend="外送$">
                                    <b-form-input v-model.number="item.price.DELIVERY" type="number" step="0.1" required></b-form-input>
                                </b-input-group>
                            </b-col>
                            <b-col class="mt-3" md="12" lg="4">
                                <b-button variant="danger" block @click="removeItem(index)">刪除</b-button>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </n-draggable>
            </b-form-group>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { mapState } from 'vuex'
import { Extra, ExtraItem } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            extra: null,
        }
    },

    async created() {
        this.extra = this.action == 'create' ? Extra() : await this.$store.dispatch('getDocument', { col: 'extras', id: this.$route.params.id })
        if (!this.extra) return this.$router.push('/extras')
        await this.$store.dispatch('bindCollectionByMerchant', { col: 'items', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            items: (state) => state.items,
        }),

        itemOptions() {
            return Object.assign([], this.items).map((item) => {
                return Object.assign(ExtraItem(), item)
            })
        },
    },

    methods: {
        async addItem(obj) {
            if (!this.extra.items.find((item) => item.id == obj.id)) return this.extra.items.push(Object.assign({}, obj))
        },

        async removeItem(index) {
            try{
                this.extra.items.splice(index, 1)
                if (this.action == 'create') return 
                await this.$store.dispatch('updateDocument', { col: 'extras', doc: this.extra })
                this.$root.$bvToast.toast('成功刪除',{title:'刪除成功',variant:'success'})
            } catch (error) {
                this.$root.$bvToast.toast(error,{title:'刪除錯誤',variant:'danger'})
            }
        },

        async onDelete() {
            const res = await this.$store.dispatch('deleteDocument', { col: 'extras', id: this.extra.id })
            this.$root.$bvToast.toast(`刪除${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/extras')
        },

        async onSubmit() {
            this.extra.merchantId = localStorage.merchantId

            const res = await this.$store.dispatch(this.action == 'create' ? 'createDocument' : 'updateDocument', { col: 'extras', doc: this.extra })
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' : '失敗'}`, { title: res ? '成功' : '失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/extras')
        },
    },
}
</script>
